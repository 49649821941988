import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7cdfc54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "labeled-item__content" }
const _hoisted_2 = { class: "labeled-item__inline-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_a_row, { justify: _ctx.justify }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "inline-label", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["justify"])
  ]))
}
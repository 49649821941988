import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b0ab2a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 mb-100 mt-20" }
const _hoisted_2 = { class: "transaction-details-meta-data-tab__meta-data-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockCardLayoutBoxedItem = _resolveComponent("BlockCardLayoutBoxedItem")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_List = _resolveComponent("List")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_List, {
            "total-items": _ctx.transactionDetailsForDisplay?.metaData?.length,
            "data-source": _ctx.transactionDetailsForDisplay?.metaData,
            "show-load-more": false,
            "is-loading": _ctx.isLoading
          }, {
            renderItem: _withCtx(({ item }) => [
              (item?.data)
                ? (_openBlock(), _createBlock(_component_a_list_item, {
                    key: 0,
                    class: "pb-4 pt-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BlockCardLayoutBoxedItem, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.jsonFormat(item?.data)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["total-items", "data-source", "is-loading"])
        ])
      ]),
      _: 1
    })
  ]))
}

import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import BlockCardLayout from "@/shared/components/cards/BlockCardLayout.vue";
import { TransactionDetailsDisplay } from "@/shared/composables/api/useGetTransactionDetails";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    transactionDetails: {
      type: Object as PropType<TransactionDetailsDisplay>,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    BlockCardLayoutBoxedItem,
    BlockCardLayout,
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, _mergeProps({
      class: [
        'tab-container',
        _ctx.hideOverflowArrows && 'tab-container--hide-overflow-arrows',
      ],
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
    }, _ctx.$attrs), {
      tabBarExtraContent: _withCtx(() => [
        _renderSlot(_ctx.$slots, "tabBarExtraContent")
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (tab) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: tab.key,
            tab: tab.title,
            disabled: tab.disabled
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, tab.key, {}, () => [
                _createTextVNode(_toDisplayString(tab.key + " content"), 1)
              ])
            ]),
            _: 2
          }, 1032, ["tab", "disabled"]))
        }), 128))
      ]),
      _: 3
    }, 16, ["class", "activeKey"])
  ]))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mb-100 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GhostListHeader = _resolveComponent("GhostListHeader")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_TransactionDetailsInputOutputCard = _resolveComponent("TransactionDetailsInputOutputCard")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_col, {
      xs: 0,
      sm: 24
    }, {
      default: _withCtx(() => [
        _createVNode(_component_GhostListHeader, null, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Inputs/Outputs")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_TransactionDetailsInputOutputCard, {
            inputs: _ctx.transactionDetailsForDisplay?.inputs,
            outputs: _ctx.transactionDetailsForDisplay?.outputs
          }, null, 8, ["inputs", "outputs"])
        ])
      ]),
      _: 1
    })
  ]))
}

import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { TransactionDetailsInputOutputCard } from "@/shared/types/cards";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import Tooltip from "@/shared/components/cards/Tooltip.vue";
import {
  Transaction_transaction_Transaction_inputs,
  Transaction_transaction_Transaction_outputs,
} from "@/api/transactions/__generated__/Transaction";
import isEmpty from "lodash/isEmpty";
import AmountText from "@/shared/components/typography/AmountText.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    inputOutputDetails: {
      type: Object as PropType<TransactionDetailsInputOutputCard>,
    },
    inputs: {
      type: Array as PropType<Transaction_transaction_Transaction_inputs[]>,
    },
    outputs: {
      type: Array as PropType<Transaction_transaction_Transaction_outputs[]>,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    AmountText,
    BlockCardLayoutBoxedItem,
    Tooltip,
  },
  methods: {
    isEmpty,
    convertAmountToAda,
  },
});

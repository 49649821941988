
import { defineComponent, computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import get from "lodash/get";
import BANCard from "@/shared/components/cards/BANCard.vue";
import TransactionDetailsCard from "@/shared/components/cards/TransactionDetailsCard.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import TabContainer, {
  TabItem,
} from "@/shared/components/layouts/TabContainer.vue";
import { useGetTransactionDetails } from "@/shared/composables/api/useGetTransactionDetails";
import TransactionDetailsInputOutputTab from "@/shared/components/tabs/TransactionDetailsInputOutputTab.vue";
import TransactionDetailsMetaDataTab from "@/shared/components/tabs/TransactionDetailsMetaDataTab.vue";
import TransactionDetailsStakeAddressTab from "@/shared/components/tabs/TransactionDetailsStakeAddressTab.vue";
import TransactionDetailsPoolsTab from "@/shared/components/tabs/TransactionDetailsPoolsTab.vue";
import TransactionDetailsDelegationTab from "@/shared/components/tabs/TransactionDetailsDelegationTab.vue";

// TODO Include load more once supported by API
export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();

    const hash = computed(() => route.params.hash);
    const { mappedDataForDisplay: transactionDetailsForDisplay, isLoading } =
      useGetTransactionDetails(hash.value);

    const cardDetails = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("Amount"),
          cardAmount: transactionDetailsForDisplay?.value?.amount,
        },
        {
          id: 2,
          cardTitle: t("Fee"),
          cardAmount: transactionDetailsForDisplay?.value?.fee,
        },
      ];
    });

    const FILTER_LABELS_MAP = {
      inputOutput: t("Inputs/Outputs"),
      delegation: t("Delegation"),
      poolsUpdate: t("Pools Update"),
      stakeAddresses: t("Stake addresses"),
      metaData: t("Metadata"),
    };

    const tabItems = ref<TabItem[]>([]);
    watch(
      transactionDetailsForDisplay,
      (newTransactionDetails) => {
        let filterCounts = newTransactionDetails?.filterCounts ?? {};

        tabItems.value = Object.entries(FILTER_LABELS_MAP).map(
          ([key, value]) => {
            const itemCount = get(filterCounts, key, 0);
            return {
              key,
              // Show title with filter item count
              title: value + ` (${itemCount})`,
              disabled: itemCount == 0,
            };
          }
        );
      },
      { immediate: true }
    );

    return {
      t,
      transactionDetailsForDisplay,
      cardDetails,
      tabItems,
      isLoading,
    };
  },
  components: {
    TransactionDetailsDelegationTab,
    TransactionDetailsPoolsTab,
    TransactionDetailsStakeAddressTab,
    TransactionDetailsMetaDataTab,
    TransactionDetailsInputOutputTab,
    BANCard,
    TransactionDetailsCard,
    GlobalSearchInput,
    PageHeaderTitle,
    TabContainer,
  },
});

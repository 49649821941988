import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mb-100 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullWrapContainer = _resolveComponent("FullWrapContainer")!
  const _component_LabeledItem = _resolveComponent("LabeledItem")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_BlockCardLayoutBoxedItem = _resolveComponent("BlockCardLayoutBoxedItem")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_List, {
            "total-items": _ctx.transactionDetailsForDisplay?.delegation?.length,
            "data-source": _ctx.transactionDetailsForDisplay?.delegation,
            "show-load-more": false,
            "is-loading": _ctx.isLoading
          }, {
            renderItem: _withCtx(({ item, index }) => [
              _createVNode(_component_a_list_item, { class: "pb-4 pt-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockCardLayoutBoxedItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, {
                        align: "middle",
                        gutter: 32
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            xs: 24,
                            sm: 12
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_LabeledItem, null, {
                                "inline-label": _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("account")), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_FullWrapContainer, {
                                    title: _ctx.t('pool'),
                                    content: item?.accountHash,
                                    "enable-copy-to-clipboard": false
                                  }, null, 8, ["title", "content"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_col, {
                            xs: 24,
                            sm: 12
                          }, {
                            default: _withCtx(() => [
                              (
                        _ctx.transactionDetailsForDisplay?.delegation &&
                        _ctx.transactionDetailsForDisplay?.delegation[index + 1] &&
                        _ctx.transactionDetailsForDisplay?.delegation[index + 1]
                          ?.poolChange
                      )
                                ? (_openBlock(), _createBlock(_component_LabeledItem, {
                                    key: 0,
                                    class: "mb-4"
                                  }, {
                                    "inline-label": _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("from pool")), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_FullWrapContainer, {
                                        title: _ctx.t('from pool'),
                                        content: 
                          _ctx.formatPoolLabel(
                            _ctx.transactionDetailsForDisplay?.delegation[index + 1]
                              ?.poolChange?.name,
                            _ctx.transactionDetailsForDisplay?.delegation[index + 1]
                              ?.poolChange?.ticker,
                            _ctx.transactionDetailsForDisplay?.delegation[index + 1]
                              ?.poolChange?.hash
                          )
                        ,
                                        "enable-copy-to-clipboard": false
                                      }, null, 8, ["title", "content"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                              (item?.poolChange)
                                ? (_openBlock(), _createBlock(_component_LabeledItem, { key: 1 }, {
                                    "inline-label": _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("to pool")), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_FullWrapContainer, {
                                        title: _ctx.t('to pool'),
                                        content: 
                          _ctx.formatPoolLabel(
                            item?.poolChange?.name,
                            item?.poolChange?.ticker,
                            item?.poolChange?.hash
                          )
                        ,
                                        "enable-copy-to-clipboard": false
                                      }, null, 8, ["title", "content"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["total-items", "data-source", "is-loading"])
        ])
      ]),
      _: 1
    })
  ]))
}
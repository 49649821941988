import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-32 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowMoreButton = _resolveComponent("ShowMoreButton")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createBlock(_component_a_list, {
    class: "list-component",
    "data-source": _ctx.dataSource,
    pagination: 
      _ctx.hidePagination
        ? false
        : {
            showQuickJumper: true,
            total: _ctx.totalItems || 0,
            showSizeChanger: true,
            pageSize: _ctx.pageSize,
            current: _ctx.currentPage,
            onChange: _ctx.onPageChange,
          }
    ,
    itemLayout: "vertical",
    split: false,
    loading: {
      spinning: _ctx.isLoading || _ctx.dataSource == undefined,
      size: 'large',
      delayTime: 500,
      tip: _ctx.t('Loading ...'),
    }
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)))
        ])
      }
    }),
    (_ctx.showLoadMore && _ctx.totalItems > 0)
      ? {
          name: "loadMore",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ShowMoreButton, {
                onClick: _ctx.loadMore,
                loading: _ctx.isShowMoreLoading
              }, null, 8, ["onClick", "loading"])
            ])
          ])
        }
      : undefined
  ]), 1032, ["data-source", "pagination", "loading"]))
}

import { defineComponent, ref, onMounted, PropType } from "vue";
import { useVModel } from "vue-composable";

export type TabItem = {
  key: string;
  title: string;
  disabled?: boolean;
};

export default defineComponent({
  props: {
    tabItems: {
      type: Array as PropType<Array<TabItem>>,
      // Key must be a String since we will use it as a name/identifier for each tab content
      default: () => [
        { key: "tab-1", title: "Tab 1" },
        { key: "tab-2", title: "Tab 2" },
      ],
    },
    activeTab: String,
  },
  setup(props) {
    const hideOverflowArrows = ref(true);
    const activeTabModel = useVModel(props, "activeTab");

    onMounted(() => {
      /** Note: fix for the bug for tab containers when transition is happening.
       * Timeout duration is just the total transition duration (enter + leave) in
       * in src/scss/animations/_transitions.scss.
       * This is to complete hide the arrows */
      setTimeout(() => (hideOverflowArrows.value = false), 600);
    });
    return {
      activeKey: props.activeTab ? activeTabModel : ref(),
      hideOverflowArrows,
    };
  },
});


import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import TransactionDetailsInputOutputCard from "@/shared/components/cards/TransactionDetailsInputOutputCard.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import { TransactionDetailsDisplay } from "@/shared/composables/api/useGetTransactionDetails";

export default defineComponent({
  props: {
    transactionDetailsForDisplay: {
      type: Object as PropType<TransactionDetailsDisplay>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    TransactionDetailsInputOutputCard,
    GhostListHeader,
  },
});

import { computed, ref, watch, onUnmounted } from "vue";
import get from "lodash/get";
import { useQuery } from "@vue/apollo-composable";
import {
  Transaction,
  Transaction_transaction_Transaction,
  TransactionVariables,
} from "@/api/transactions/__generated__/Transaction";
import { transactionDetailsGql } from "@/api/transactions/transactionDetails";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { convertAmountToAda } from "@/shared/utils/numbers";

type InputOutputItem = {
  address?: string | null;
  amount?: string | null;
};
import { formatDate, formatTime } from "@/shared/utils/date";

type InputOutputListItem = {
  input: InputOutputItem | null;
  output: InputOutputItem | null;
};

export type TransactionDetailsDisplay = ReturnType<
  typeof transactionDetailsDisplayMapper
>;

const transactionDetailsDisplayMapper = (
  item: Transaction_transaction_Transaction | null
) => {
  if (!item) return null;

  // Parse list for inputs and outputs for display
  const inputs = (item?.inputs || []).map((i) => ({
    address: i?.address,
    amount: convertAmountToAda(i?.amount),
  }));
  const outputs = (item?.outputs || []).map((i) => ({
    address: i?.address,
    amount: convertAmountToAda(i?.amount),
  }));
  const inputOutputLists = [] as InputOutputListItem[];
  const inputOutputListLength = Math.max(inputs.length, outputs.length);
  for (let i = 0; i < inputOutputListLength; i++) {
    inputOutputLists.push({
      input: get(inputs, i, null),
      output: get(outputs, i, null),
    });
  }

  return {
    hash: item?.hash,
    block: item?.block?.hash,
    epoch: item?.block?.epochNo,
    slot: item?.block?.slotNo,
    date: formatDate(item?.block?.timestamp),
    time: formatTime(item?.block?.timestamp),
    message: item?.message,

    amount: convertAmountToAda(item?.amount),
    fee: convertAmountToAda(item?.fee),
    inputs: item?.inputs,
    outputs: item?.outputs,
    inputOutputLists,
    delegation: item?.delegations,
    poolsUpdate: item?.poolsUpdates,
    stakeAddresses: item?.stakeAddresses,
    metaData: item?.metadata,
    filterCounts: {
      inputOutput: inputs.length + outputs.length,
      delegation: item?.delegations?.length ?? 0,
      poolsUpdate: item?.poolsUpdates?.length ?? 0,
      stakeAddresses: item?.stakeAddresses?.length ?? 0,
      metaData: item.metadata?.length ?? 0,
    },
  };
};

// TODO: Add additional filters as needed if a screen has the need to support custom filters
export const useGetTransactionDetails = (hash) => {
  const transactionDetailsDisplay =
    ref<ReturnType<typeof transactionDetailsDisplayMapper>>();
  const transactionDetails = useQuery<Transaction, TransactionVariables>(
    transactionDetailsGql,
    () => ({
      hash,
    })
  );

  const parsedGqlTransactionDetails = computed(() =>
    parseGqlResponse<Transaction_transaction_Transaction>(
      "Transaction",
      transactionDetails?.result?.value
    )
  );

  watch(parsedGqlTransactionDetails, (newParsedGqlTransactionDetails) => {
    if (!newParsedGqlTransactionDetails.data) return;

    transactionDetailsDisplay.value = transactionDetailsDisplayMapper(
      newParsedGqlTransactionDetails.data
    );
  });

  const timerId = window.setInterval(() => {
    transactionDetails.refetch({
      hash,
    });
  }, 5000);

  onUnmounted(() => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  });

  return {
    mappedDataForDisplay: transactionDetailsDisplay,
    isLoading: transactionDetails?.loading,
  };
};


import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import List from "@/shared/components/lists/List.vue";
import { TransactionDetailsDisplay } from "@/shared/composables/api/useGetTransactionDetails";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";

export default defineComponent({
  props: {
    transactionDetailsForDisplay: {
      type: Object as PropType<TransactionDetailsDisplay>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
      metaData: JSON.stringify(
        {
          msg: ["Refund for order# 1193 -kcl"],
        },
        null,
        4
      ),
    };
  },
  components: {
    BlockCardLayoutBoxedItem,
    List,
  },
  methods: {
    jsonFormat(data: string | null) {
      let returnObject: string | null = null;

      // Dual parsing due to escape characters present on API responses
      if (data) {
        try {
          returnObject = JSON.parse(data);

          try {
            if (returnObject) {
              returnObject = JSON.parse(returnObject);
            }
          } catch (e) {
            returnObject = JSON.parse(data);
          }
        } catch (e) {
          returnObject = null;
        }
      }

      return returnObject;
    },
  },
});

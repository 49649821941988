
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import List from "@/shared/components/lists/List.vue";
import { TransactionDetailsDisplay } from "@/shared/composables/api/useGetTransactionDetails";
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import LabeledItem from "@/shared/components/layouts/LabeledItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    transactionDetailsForDisplay: {
      type: Object as PropType<TransactionDetailsDisplay>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const t = useI18n().t;
    return {
      t,
    };
  },
  components: {
    FullWrapContainer,
    LabeledItem,
    BlockCardLayoutBoxedItem,
    List,
  },
});

import gql from "graphql-tag";

export const transactionDetailsGql = gql`
  query Transaction($hash: String!) {
    transaction(hash: $hash) {
      ... on Transaction {
        id
        hash
        block {
          id
          hash
          epochNo
          slotNo
          timestamp
        }
        message
        amount
        fee
        inputs {
          address
          amount
        }
        outputs {
          address
          amount
        }
        delegations {
          poolChange {
            hash
            name
            ticker
          }
          accountHash
        }
        poolsUpdates {
          poolsUpdateType
          activeEpochNo
          poolHash
        }
        stakeAddresses {
          accountHash
          stakeAddressType
          poolRewardAddress
        }
        metadata {
          data
        }
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;

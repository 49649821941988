
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ShowMoreButton from "@/shared/components/buttons/ShowMoreButton.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    return { t };
  },
  components: {
    ShowMoreButton,
  },
  props: {
    totalItems: { type: Number },
    item: { type: Array },
    currentPage: { type: Number },
    pageSize: { type: Number },
    hidePagination: { type: Boolean, default: true },
    onPageChange: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      default: (page: number, pageSize: number) => true,
    },
    loadMore: {
      type: Function,
    },
    isShowMoreLoading: {
      type: Boolean,
    },
    loadMoreButtonText: {
      type: Function,
    },
    showLoadMore: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
});


import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    isEven: {
      type: Boolean,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
});

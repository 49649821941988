import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7775ef1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ghost-list-header__header-title" }
const _hoisted_2 = { class: "ghost-list-header__header-subtitle" }
const _hoisted_3 = { class: "ghost-list-header__header-title-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    justify: "space-between",
    align: "middle",
    class: "ghost-list-header"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        lg: 12,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
          ])
        ]),
        _: 3
      }),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "title-2", {}, undefined, true)
      ])
    ]),
    _: 3
  }))
}
import isEmpty from "lodash/isEmpty";

export const formatNullableString = (
  string: string | null | undefined,
  formattedString: string
): string => {
  return string ? formattedString : "";
};

/**
 * Format label based on poolHash object
 */
export const formatPoolLabel = (
  poolHashName: string | null | undefined,
  poolHashTicker: string | null | undefined,
  poolHash?: string | null
): string => {
  const nameLabel = formatNullableString(poolHashName, `${poolHashName}`);
  const tickerLabel = formatNullableString(
    poolHashTicker,
    `[${poolHashTicker}] `
  );

  if (isEmpty(poolHashName) && isEmpty(poolHashTicker)) {
    return poolHash as string;
  }

  if (isEmpty(poolHashName) && !isEmpty(poolHashTicker)) {
    return poolHashTicker as string;
  }

  return [poolHashTicker && tickerLabel, poolHashName && nameLabel].join("");
};
